import React from'react';
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col, Slider, Radio } from "antd";
import Layout from '../../components/Layouts';
import Img from 'gatsby-image';
import './BeamSearch.css';

export const frontmatter = {
    title: `Effect of Beam Search Parameters`,
    written: `2019-07-26`,
    updated: `2019-07-28`,
    layoutType: `post`,
    contentType: `dataviz`,
    path: `/nlg-beamsearch/`,
    category: `VISUALISATION`,
    image: `./poster.jpg`,
    description: `Effect of beam width, max steps, max hypothesis on Natural Langugage Generation`    
}


const ImageGrid = (props) => {
    // const data = useStaticQuery(graphql`
    // {
    //   allFile(filter: {relativePath: {regex: "/nlg-beamsearch/images/"}}) {
    //     edges {
    //       node {
    //         name
    //         relativePath
    //         childImageSharp {
    //         small: fluid(maxHeight: 200, maxWidth: 200) {
    //           src
    //           srcSet
    //           aspectRatio
    //           sizes
    //           tracedSVG
    //           }          
    //         }
    //       }
    //     }
    //   }
    // }
    // `)    
    return(        
    <div style={{width: "400px", overflow: "hidden", textAlign: "center", overflow: "hidden", margin: "0 auto"}}>
        {          
        //   props.data.allFile.edges.filter(file => file.node.name.includes("crop")).map((file, index) => {
          props.data.allFile.edges.map((file, index) => {
              let cls = "image-col";
              let fname = file.node.name;
              if (props.activeImage) {
                  cls = props.activeImage.includes(fname) ? "image-col active-image" : "image-col ";
              } else {
                // cls = index == 0 ? "image-col active-image" : "image-col "
              }              
              let sizes="(max-width: 150px) 100vw, 150px"
            return (
                <Col key={`img-col-${index}`} span={6} className={cls} onClick={() => props.onClick(props.data.allFile.edges, fname)}>
                    <div className="cen">
                        <Img fluid={{...file.node.childImageSharp.small, sizes}}/>
                    </div>
                </Col>
            )
          })
        }
    </div>
    )
  }

const beam_width_arr = [2, 4, 8, 16, 32];
const max_steps_arr =  [2, 4, 8, 16, 32];
const max_hypothesis_arr  = [2, 4, 8, 16, 32];
// const beam_width_arr = [2, 4, 8, 16, 32, 30, 35];
// const max_steps_arr =  [50, 75, 100, 200];
// const max_hypothesis_arr  = [2, 4, 8, 10, 12, 16, 24, 32];
// const beam_width_slider = beam_width_arr.reduce((acc, item, index) => {
//     acc[index] = item
//     return acc
// }, {});

export default class BeamSearchEffect extends React.Component {    
    state = {
        k: 2,
        m: 4,
        s: 8,
        activeImage: null
    }

    handleImageClick = (edges, activeImage) => {
        let selectedImages = edges.filter(item => item.node.name === activeImage);
        if (selectedImages.length > 0) {
            let selectedImage = selectedImages[0].node;
            this.setState({selectedImage});
        }
        this.setState({activeImage});
    }

    findCaption = () => {
        let { activeImage, k, m ,s } = this.state;
        let data = this.props.data.allBeamSearchEffectsJson.edges.filter(edge => edge.node.fname.includes(activeImage));
        if (data && data.length > 0) {
            let caption = data[0].node.values.filter(item => {
                return item.k === k && item.m === m && item.s === s;
            })

            if (caption.length > 0) {
                return caption[0].text;
            }            
        }
    }

    handleBeamWidthChange = (e) => {
        let k = e.target.value;
        this.setState({ k });
    }

    handleMaxHypothesisChange = (e) => {
        let m = e.target.value;
        this.setState({ m });
    }

    handleMaxStepsChange = (e) => {
        let s = e.target.value;
        this.setState({ s });
    }

    render() {        
        const { k, m, s, activeImage, selectedImage } = this.state;      
        let caption = this.findCaption();  
        let sizes="(max-width: 400px) 100vw, 400px";
        return(
            <Layout data={this.props.data} location={this.props.location}>
            <div>
                <div>
                    <h1 className="header-title" style={{marginTop: 12, marginBottom: 12}}>Beam Search: Effect of Parameters</h1>
                    <h4 className="header-subtitle" style={{marginTop: 0, marginBottom: 16}}>28 July, 2019</h4>                
                </div>
                <div className="story-content" style={{marginBottom: 20, lineHeight: 1.5}}>
                    <p>
                        In my post on <a href="/nic-p1">image captioning</a> and <a href="/nlg-decoders">visualising decoding algorithms</a>, 
                        we used beam search as one of the decoding algorithm
                        to generate captions. In both posts, we only saw the end result i.e the caption generated by the algorithm but we did not 
                        discuss the impact of various algorithm specific parameters on the end result.
                        This page is my attempt to address that by showing the impact of following parameters on captions generated using beam search decoder:
                    </p>
                     <ul style={{marginTop: "10px", lineHeight: 1.5}}>
                         <li><b>Beam Width</b>: How many words to keep track of at every step</li>
                         <li><b>Max Hypotheses</b>: What is the maximum number of hypotheses after which the algorithm stops</li>
                         <li><b>Max Steps</b>: What is the maximum number of steps after which the algorithm stops</li>
                     </ul>
                </div>
                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                    <ImageGrid data={this.props.data} activeImage={activeImage} onClick={this.handleImageClick}/>
                    <p style={{fontSize: 10, textAlign: "center"}}>Click on the image to see the caption</p>
                </div>                    
                {/* <div style={{display: "block", overflow: "hidden", textAlign: "center", overflow: "hidden", margin: "0 auto", width: "50%"}}> */}
                {/* <div style={{width: "600px", overflow: "hidden", textAlign: "center", margin: "0px auto", marginBottom: "20px"}}>  */}
                <div className="bs-action-container"> 
                {/* {
                    beam_width_slider &&
                    <Slider 
                        min={beam_width_slider[0]}
                        max = {beam_width_slider[Object.keys(beam_width_slider).length-1]}
                        step={null}
                        marks={beam_width_slider}
                        />
                } */}
                
                <Row>
                    <Col span={8} className="beamsearch-action-label">
                        <span>Beam Width</span>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 16, offset: 0}} className="beamsearch-action-buttons">
                            <Radio.Group value={k} onChange={this.handleBeamWidthChange} size="small">
                                {
                                    beam_width_arr.map((item, index) => {
                                        return <Radio.Button key={`krb-${index}`} value={item}>{item}</Radio.Button>
                                    })
                                }
                            </Radio.Group> 
                    </Col>                    
                </Row>
                <Row> 
                    <Col span={8} className="beamsearch-action-label">
                        <span>Max Hypotheses</span> 
                    </Col>
                <Col xs={{span: 24}} md={{span: 16, offset: 0}} className="beamsearch-action-buttons">                    
                    <Radio.Group value={m} onChange={this.handleMaxHypothesisChange} size="small">                        
                        {
                            max_hypothesis_arr.map((item, index) => {
                                return <Radio.Button key={`mrb-${index}`} value={item}>{item}</Radio.Button>
                            })
                        }
                    </Radio.Group> 
                </Col>
                </Row>
                <Row>
                    <Col span={8} className="beamsearch-action-label">
                        <span>
                        Max Steps
                        </span>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 16, offset: 0}} className="beamsearch-action-buttons">
                        <Radio.Group value={s} onChange={this.handleMaxStepsChange} size="small">
                                {
                                    max_steps_arr.map((item, index) => {
                                        return <Radio.Button key={`srb-${index}`} value={item}>{item}</Radio.Button>
                                    })
                                }
                        </Radio.Group> 
                    </Col>
                </Row>
                </div>

                {/* </div> */}
                <div style={{display: "block", overflow: "hidden", textAlign: "center", overflow: "hidden", margin: "0 auto", maxWidth: "60rem"}}>
                    <div style={{display: "block", overflow: "hidden", textAlign: "center", overflow: "hidden", margin: "0 auto", width: "400px"}}>
                        {/* <div style={{display: "block", overflow: "hidden", textAlign: "center", overflow: "hidden", margin: "10 auto", width: "400px"}}> */}
                        <div>
                            <p className="gen-caption" style={{height: "80px"}}>{caption}</p>
                            {/* {selectedImage && <Img fluid={selectedImage.childImageSharp.small} />} */}
                            {selectedImage && <Img fluid={{...selectedImage.childImageSharp.small, sizes}} />}
                        </div>                
                    </div>
                    <div className="story-content" style={{marginTop: 10, marginBottom: 40, lineHeight: 1.5}}>
                        <p>
                            Overall, I found that increasing the beam width and max hypotheses tend to generate 
                            better captions.
                            This makes sense because as we increase the beam width, we keep track of more words at every step and 
                            increase the chances of finding a better caption. Although for some images high beam width seem to 
                            generate unfinished captions 🤔. Similarly, as we increase the max hypotheses 
                            we relax the stopping criteria and let the model see more of potential candidates (hypotheses).
                            The max steps does not have any impact on the caption as long as we keep it sufficiently 
                            large enough (e.g 32 and above).
                        </p>
                    </div>
                </div>
            </div>
        </Layout>            
        )
    }
}

export const pageQuery = graphql `
query {
    allFile(filter: {relativePath: {regex: "/nlg-beamsearch/images/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
            small: fluid (maxWidth: 500) {
              src
              srcSet
              aspectRatio
              sizes
              tracedSVG
              }          
            }
          }
        }
      }    
    allBeamSearchEffectsJson {
        edges {
          node {
            fname   
            values {
                k
                m
                s
                text
              }                           
          }
        }
    }    
}
`
